.blog-sidebar {
  overflow: auto;
  padding: 3.5rem 15px 3rem 3.5rem;
  background-color: #4D94A8;
  position: absolute;
  right: 0;
  min-height: 80vh;
  @media screen and (max-width: 991px) {
    min-height: auto;
    position: relative;
    padding: 2.5rem 2rem;
    margin-bottom: 2rem;
  }
  ul {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      &.title {
        @include font-family('dc');
        color: #FFFFFF;
      }
      a {
        color: #FFFFFF;
        @include font-family('ks-light');
      }
    }
  }
}
