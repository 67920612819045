.alternator {
  position: relative;
  &--blue {
    .alternator {
      &__background {
        background-color: #4f748a;
      }

      &__image {
        @include media($tablet) {
          order: 1;
          &:before {
            left: 0;
          }
        }
      }

      &__content {
        @include media($tablet) {
          order: 0;
          padding-left: 90px;
          padding-right: 7.5vw;
        }
      }
    }
  }

  &--red {
    .alternator {
      &__background {
        background-color: #934637;
      }

      &__image {
        @include media($tablet) {
          order: 0;
          &:before {
            right: 0;
          }
        }
      }

      &__content {
        @include media($tablet) {
          order: 1;
          padding-left: 7.5vw;
          padding-right: 90px;
        }
      }
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    @include media($mobile-only) {
      height: 100%;
    }
    @include media($tablet) {
      top: 50px;
      height: calc(100% - 100px);
    }
  }

  &__container {
    position: relative;
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    @include media($tablet) {
      display: flex;
    }
  }

  &__image {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include media($mobile-only) {
      padding-bottom: 133%;
    }
    @include media($tablet) {
      width: 44%;
    }
    &:before {
      display: block;
      position: absolute;
      top: 50px;
      width: 50px;
      height: calc(100% - 100px);
      box-shadow: 2px 0 40px rgba($black, 0.7);
      @include media($tablet) {
        content: '';
      }
    }
    &-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    img {
      position: relative;
      width: 100%;
    }
  }

  &__content {
    position: relative;
    padding: 50px 20px;
    @include media($tablet) {
      width: 56%;
      padding: 105px 0 115px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: calc(100% - 100px);
      background: url(img/title-background.png) no-repeat;
      background-size: contain;
      background-position: center;
      mix-blend-mode: multiply;
    }
    &-title {
      margin-bottom: 20px;
      padding: 20px 0;
      @include font-family('ks-bold');
      font-size: 36px;
      font-weight: normal;
      line-height: 1.2;
      letter-spacing: 0;
      color: #f7f4ea;
      text-transform: none;
      text-align: center;
      @include media($tablet) {
        padding: 20px;
        font-size: 48px;
      }
      span {
        position: relative;
        display: block;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          border-top: 2px dashed #ce6843;
          left: 50%;
          transform: translateX(-50%);
        }

        &:before {
          width: 86.2%;
          top: -20px;
        }

        &:after {
          width: 65.5%;
          bottom: -20px;
        }
      }
    }

    &-subtitle {
      margin-bottom: 45px;
      @include font-family('dc');
      font-size: 24px;
      font-weight: normal;
      line-height: 1.13;
      letter-spacing: 0.024em;
      color: #f7f4ea;
      text-align: center;
      @include media($tablet) {
        font-size: 30px;
      }
    }

    &-body {
      @include font-family('ks-light');
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0;
      color: #f7f4ea;
      opacity: 0.7;
      @include media($tablet) {
        font-size: 20px;
      }
    }

    &-links {
      margin-top: 50px;
      text-align: center;
    }
  }
}
