.copy-rights {
  padding: 5px 0;
  @include media($mobile-only) {
    padding: 5px 10px;
  }
  label, a {
    display: inline-block;
    @include font-family('ks-light');
    color: #2E1C16;
    font-size: 14px;
  }
  a {
    padding: 0 15px;
    @include media($mobile-only) {
      padding: 0 5px 0 0;
    }
  }
  .right-block {
    text-align: right;
    @include media($mobile-only) {
      text-align: left;
    }
  }
}
