.home-intro {
  position: relative;
  @include media($tablet) {
    display: flex;
    height: 50vw;
    max-height: 800px;
  }
  &__h-half {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include media($tablet) {
      width: 50%;
    }
  }

  &__v-half {
    position: relative;
    width: 100%;
    height: 50%;
  }

  &__background {
    padding-bottom: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include media($tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 0;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    &--black {
      &:after {
        background-color: #4a4a4a;
        mix-blend-mode: multiply;
      }
    }

    &--orange {
      &:after {
        background-color: #ce6843;
      }
    }

    &--teal {
      &:after {
        background-color: #438192;
        mix-blend-mode: multiply;
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    &-title {
      &-sub {
        @include font-family('dc');
        font-size: 20px;
        color: #80c7db;
        letter-spacing: 0.39px;
        text-align: center;
        line-height: 1.13;
        text-transform: uppercase;
        @include media($huge) {
          font-size: 30px;
        }
      }

      &-link {
        position: relative;
        display: inline-block;
        padding: 0 65px;
        @include font-family('ks-bold');
        font-size: 40px;
        letter-spacing: -1px;
        line-height: 50px;
        @include media($huge) {
          line-height: 70px;
          font-size: 60px;
        }

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          width: 43px;
          height: 43px;
          border-bottom: 1px solid;
          border-left: 1px solid;
          z-index: -1;
          @include media($huge) {
            width: 58px;
            height: 58px;
          }
        }
        &:before {
          left: 43px;
          transform: translateY(-50%) rotate(45deg);
          @include media($huge) {
            left: 35px;
          }
        }

        &:after {
          right: 43px;
          transform: translateY(-50%) rotate(-135deg);
          @include media($huge) {
            right: 35px;
          }
        }
        span {
          position: relative;
          display: block;
          padding: 5px 0;
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
          }
          &:before {
            top: 0;
          }

          &:after {
            bottom: 0;
          }
        }

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 50px;
          height: auto;
          &:first-child {
            left: 0;
          }
          &:last-child {
            right: 0;
          }
        }
      }

      &--teal {
        .home-intro__content-title {
          &-sub {
            color: #80c7db;
          }

          &-link {
            color: #80c7db;
            &:before,
            &:after {
              border-color: #80c7db;
            }
            svg {
              path {
                fill: #80c7db;
              }
            }
            span {
              &:before,
              &:after {
                background-color: #80c7db;
              }
            }
          }
        }
      }

      &--offwhite {
        .home-intro__content-title {
          &-sub {
            color: #f7f4ea;
          }

          &-link {
            color: #f7f4ea;
            &:before,
            &:after {
              border-color: #f7f4ea;
            }
            svg {
              path {
                fill: #f7f4ea;
              }
            }
            span {
              &:before,
              &:after {
                background-color: #f7f4ea;
              }
            }
          }
        }
      }

      &--yellow {
        .home-intro__content-title {
          &-sub {
            color: #ffbb00;
          }

          &-link {
            color: #ffbb00;
            &:before,
            &:after {
              border-color: #ffbb00;
            }
            svg {
              path {
                fill: #ffbb00;
              }
            }
            span {
              &:before,
              &:after {
                background-color: #ffbb00;
              }
            }
          }
        }
      }
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 17.8vw;
    height: 15.3vw;
    padding: 2.5vw 3.75vw;
    background: #4f748a;
    box-shadow: 0 0 40px rgba(#2e1c16, 1);
    @include media($desktop) {
      display: block;
    }
  }
}
