.full-width-cards {
  .cards-block {
    background: url('img/topo-background.jpg') no-repeat;
    background-size: cover;
  }
  .card {
    position: relative;
    transition: 1s solid ease-in;
    @include media($mobile-only) {
      margin-bottom: 7rem;
    }
    img {
      width: 100%;
    }
    .content {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      // display: none;
      background-color: rgba(111, 132, 99, 0.75);
      &.teal {
        background-color: rgba(111, 132, 99, 0.75);
      }
      &.yellow {
        background-color: rgba(217, 154, 42, 0.75);
      }
      &.blue {
        background-color: rgba(77, 148, 168, 0.75);
      }
      &.orange {
        background-color: rgba(206, 104, 67, 0.75);
      }
      h4 {
        &.title {
          color: #fff;
          text-align: center;
          font-size: 22px;
          align-items: center;
          border-top: 1px solid #f7f4ea;
          border-bottom: 1px solid #f7f4ea;
          display: inline-block;
          margin-top: calc(25% + 30px);
          padding: 12px 50px 10px;
          position: relative;
          @include btn-decoration(40px, 40px, white, -58px, -58px, 5px);
          @include media($mobile-only) {
            margin-top: 25%;
            padding: 15px 0px 15px;
            width: 220px;
            @include btn-decoration(40px, 40px, white, -54px, -54px, 11px);
          }
        }
      }
      .left-triangle {
        &:before {
          position: absolute;
          transform: rotate(-45deg);
          content: '';
          width: 43px;
          border-top: 1px solid #fff;
          border-left: 1px solid #fff;
          left: -22px;
          top: 8px;
          height: 42px;
        }
      }
      .right-triangle {
        &:before {
          position: absolute;
          transform: rotate(135deg);
          content: '';
          width: 43px;
          border-top: 1px solid #fff;
          border-left: 1px solid #fff;
          right: -22px;
          top: 8px;
          height: 42px;
        }
      }
    }
    &:hover {
      .content {
        display: block;
      }
    }
  }
  .cards-desc {
    text-align: center;
    padding: 10px;
    position: relative;
    top: 6rem;
    h4 {
      .left-triangle {
        &:before {
          border: none !important;
        }
      }
      .right-triangle {
        &:before {
          border: none !important;
        }
      }
    }
    p {
      @include font-family('ks-light');
      color: $text;
      font-size: 18px;
      line-height: 1.5;
    }
    @include hex-btn-fill(rgba(206, 104, 67, 1), #fff);
    &.teal {
      @include hex-btn-fill(rgba(111, 132, 99, 1), #fff);
    }
    &.yellow {
      @include hex-btn-fill(rgba(217, 154, 42, 1), #fff);
    }
    &.blue {
      @include hex-btn-fill(rgba(77, 148, 168, 1), #fff);
    }
    &.orange {
      @include hex-btn-fill(rgba(206, 104, 67, 1), #fff);
    }
    padding: 10px 60px 10px;
  }
}
