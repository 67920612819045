.logo {
  @include position-absolute;
  width: 250px;
  height: 200px;
  transition: 1.5s all ease;
  z-index: 99;
  @media screen and (max-width: 991px) {
    position: fixed;
    left: 0;
    bottom: inherit;
    top: 0;
    right: inherit;
    width: 100px;
    height: 85px;
    z-index: 100;
  }
  &.sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    width: 200px;
    height: 150px;
    z-index: 100;
    @media screen and (max-width: 991px) {
      position: fixed;
      left: 0;
      bottom: inherit;
      top: 0;
      right: inherit;
      width: 100px;
      height: 85px;
      z-index: 100;
    }
    .logo-wrapper {
      padding: 1.5rem;
    }
  }
  .logo-wrapper {
    background-color: #4F748A;
    text-align: center;
    height: 100%;
    padding: 2rem;
    @media screen and (max-width: 991px) {
      padding: 10px !important;
    }
    img {
      max-height: 100%;
      margin: auto;
    }
  }
  &.show {
    width: 150px !important;
    height: 100px !important;
    .logo-wrapper {
      padding: 1rem;
    }
  }
}
