.landing-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 38px 45px;
  background-color: $primary;
  text-align: right;
  z-index: 10;
  @media screen and (min-width: 769px) {
    padding: 38px 65px;
  }
  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 130px;
    padding: 15px 30px;
    background: #4f748a;
    box-shadow: 0 0 40px black;
    @media screen and (min-width: 769px) {
      width: 180px;
      padding: 25px 40px;
    }
  }

  &__link {
    display: inline-block;
    @include font-family("dc");
    font-size: 24px;
    color: #f7f4ea;
    line-height: 1;
  }
}
.landing-footer {
  margin-top: 30px;
  height: 52px;
  background-color: #dadada;
}

.landing-hero {
  &--option-one {
    position: relative;
    padding: 180px 20px 50px;
    @media screen and (min-width: 769px) {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }
    .landing-hero {
      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &__content {
        position: relative;
        z-index: 5;
        margin-bottom: 30px;
        @media screen and (min-width: 769px) {
          width: 100%;
          margin-bottom: 0;
          padding-left: calc((100vw - 1180px) / 2);
        }
        &-container {
          padding-left: 10px;
        }

        &-title {
          // max-width: 390px;
          margin-bottom: 20px;
          @include font-family("ks-bold");
          font-size: 50px;
          color: #f7f4ea;
          letter-spacing: 0.083em;
          line-height: 1;
        }

        &-subtitle {
          p {
            max-width: 390px;
            margin-bottom: 20px;
            @include font-family("ks-bold");
            font-size: 30px;
            line-height: 1;
            letter-spacing: 0.083em;
            color: #ffffff;
          }
        }

        &-body {
          p {
            padding-right: 30px;
            @include font-family("ks-light");
            font-size: 23px;
            line-height: 1;
            color: #f7f4ea;
            letter-spacing: 0.083em;
          }
        }
        &-links {
          margin-top: 20px;
        }
      }

      &__form {
        position: relative;
        @media screen and (min-width: 769px) {
          width: 100%;
          padding-right: calc((100vw - 1180px) / 2);
        }
        &-container {
          @media screen and (min-width: 769px) {
            padding-right: 70px;
          }
        }

        label {
          display: block;
          margin-bottom: 10px;
          @include font-family("ks-bold");
          font-size: 25px;
          font-style: normal;
          color: #ffffff;
          letter-spacing: 0.083em;
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        textarea {
          border: 1px solid black;
          background: white;
          border-radius: 7px;
          height: 50px;
          padding: 9px 15px;
          color: #5f5f5f;
          width: 100%;
        }

        input[type="submit"] {
          background: #d24f28;
          @include font-family("ks-bold");
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 1.33px;
          text-transform: uppercase;
          border-radius: 0;
          border: 0;
          min-width: 200px;
        }
      }
    }
  }

  &--option-two {
    position: relative;
    min-height: 600px;
    margin-top: 100px;
    margin-bottom: 120px;
    @media screen and (min-width: 769px) {
      display: flex;
    }
    .landing-hero {
      &__background {
        width: 100%;
        padding-bottom: 62.5%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @media screen and (min-width: 769px) {
          width: 50%;
          padding-bottom: 0;
        }
      }
      &__content {
        background-color: #4e676e;
        @media screen and (min-width: 769px) {
          width: 50%;
          padding-left: calc((100vw - 940px) / 2);
        }
        &-container {
          padding: 70px 20px;
        }

        &-title {
          margin-bottom: 40px;
          @include font-family("ks-bold");
          font-size: 36px;
          color: #ffffff;
          letter-spacing: 0.083em;
          line-height: 1.1875;
          @media screen and (min-width: 769px) {
            font-size: 48px;
          }
        }

        &-subtitle {
          max-width: 780px;
          width: 100%;
          padding: 0 0 30px;
          @include font-family("ks-bold");
          font-size: 26px;
          line-height: 1.2;
          color: #ffffff;
          @media screen and (min-width: 769px) {
            position: absolute;
            padding: 25px 30px;
            text-align: center;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            background-color: #ce6843;
            font-size: 50px;
          }
        }

        &-body {
          @include font-family("ks-bold");
          font-size: 18px;
          line-height: 1.2;
          color: #ffffff;
          letter-spacing: 0.083em;
          @media screen and (min-width: 769px) {
            font-size: 30px;
          }
        }
      }
    }
  }

  &--option-three {
    position: relative;
    padding: 150px 0 90px;
    background: url("img/landing-hero-bg.jpg") no-repeat center center;
    background-size: cover;
    @media screen and (min-width: 769px) {
      display: flex;
      margin-bottom: 120px;
    }
    .landing-hero {
      &__background {
        width: 100%;
        padding-bottom: 62.5%;
        box-shadow: -10px 2px 20px 0 rgba(0, 0, 0, 0.5);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @media screen and (min-width: 769px) {
          width: 50%;
          padding-bottom: 0;
        }
      }
      &__content {
        @media screen and (min-width: 769px) {
          width: 50%;
          padding: 60px 0;
          padding-left: calc((100vw - 1180px) / 2);
        }
        &-container {
          padding: 30px 20px;
          background-color: #ce6843;
          @media screen and (min-width: 769px) {
            padding: 56px 40px 56px 48px;
          }
        }

        &-title {
          margin-bottom: 15px;
          @include font-family("ks-bold");
          font-size: 50px;
          color: #f7f4ea;
          letter-spacing: 0;
          line-height: 1;
          @media screen and (min-width: 769px) {
            font-size: 77px;
          }
        }

        &-subtitle {
          width: 100%;
          padding: 0 0 15px;
          @include font-family("ks-bold");
          font-size: 32px;
          line-height: 1.2;
          color: #ffffff;
          background-color: #ce6843;
          @media screen and (min-width: 769px) {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            max-width: 980px;
            padding: 25px 30px;
            font-size: 50px;
            text-align: center;
          }
        }

        &-body {
          p {
            @include font-family("dc");
            font-size: 31px;
            line-height: 1.4;
            color: #f7f4ea;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}
