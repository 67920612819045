// *************************************
//
//  Helpers
//  -> These are project-specific Extends,
//  Functions, Mixins, Animations, Grid Classes
//
//  --- Edit the following on this sheet: --- //
//
// *************************************

// -------------------------------------
//  Extends
// -------------------------------------


// -------------------------------------
//  Functions
// -------------------------------------
//Numbers must be between 1 and 10;
//LIGHTEN: Numbers from -10 to -1 will darken the color;
//DARKEN: Numbers from 1 to 10 will lighten the color;
//To use, add this function to your scss: e.g., background-color: custom-color(red, 2);
@function custom-color($color, $number) {
	@if($number < 0 and $number > -11){
		$amount: ($number * -1) * 10%;
		@return darken($color, $amount);
	} @elseif ($number > 0 and $number < 11) {
		$amount: ($number) * 10%;
		@return lighten($color, $amount);
	} @else {
		@return $color;
	}
}


// -------------------------------------
//  Mixins
// -------------------------------------

@mixin line-behind {
	height: 12px;
	margin: 40px 0;

	border-bottom: 1px solid darken(white, 10%);
	text-align: center;

	h2, h4 {
		display: inline;
		padding: 0 10px;
		background: white;
	}

	h2 {
		font-size: 36px;
		line-height: 1.5;
	}
}
@mixin font-family($type) {
	font-family: $type;
}
.line-behind-text {
	@include line-behind;
}

//Linear gradient for all other all browsers EXCEPT IE
@mixin gradient($start, $color-stops...){
	@if ($start != "") {
		$start: top;
	}

	background-color: transparent;
	background-image: -webkit-linear-gradient($start, $color-stops);
	background-image: -moz-linear-gradient($start, $color-stops);
	background-image: -o-linear-gradient($start, $color-stops);
	background-image: -ms-linear-gradient($start, $color-stops);
	background: linear-gradient(to $start,$color-stops);
}

// Linear gradient mixin that should work with IE8 and below -- add this mixin ONLY to ie-specific stylesheet. Use ColorZilla svg code for IE9.
@mixin gradient-ie($color1, $color2) {
	$first-ie: ie-hex-str($color1);
	$second-ie: ie-hex-str($color2);
	// background-color: mix($color1, $color2)!important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$first-ie}', endColorstr='#{$second-ie}', gradientType='0' GradientType =1)!important;
	-ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$first-ie}', endColorstr='#{$second-ie}', gradientType='0' GradientType =1)!important;
	width: 100%;
	height: 100%;
}

@mixin triangle-sprite {
	width: 0;
	height: 0;
	border-top: 100px solid transparent;
	border-bottom: 100px solid transparent;
	border-right:100px solid $white;
}

@mixin col-with-gutter( $cols, $gutter ){
	@include span-columns($cols);
	margin-right: $gutter;
}

@mixin position-absolute {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	margin: auto;
}

/* Button decoration */
@mixin btn-decoration($width, $height, $color, $left, $right, $top) {
	&:before {
		content: url('img/icons/button-decoration--left-#{$color}.svg');
		position: absolute;
		left: $left;
		height: $width;
		width: $height;
		top: $top;
	}
	&:after {
		content: url('img/icons/button-decoration--left-#{$color}.svg');
		position: absolute;
		right: $right;
		height: $width;
		width: $height;
		transform: rotate(180deg);
		top: $top - 3px;
	}
}

@mixin btn-decoration-top-bottom($width, $height, $color, $top, $bottom, $left) {
	&:before {
		content: url('img/icons/button-decoration--left-#{$color}.svg');
		position: absolute;
		top: $top;
		height: $width;
		width: $height;
		left: $left;
		transform: rotate(90deg);
	}
	&:after {
		content: url('img/icons/button-decoration--right-#{$color}.svg');
		position: absolute;
		bottom: $bottom;
		height: $width;
		width: $height;
		left: $left;
		transform: rotate(90deg);
	}
}

// Hex Button
@mixin hex-btn-fill($bg, $color) {
	h4 {
		color: $color;
		text-align: center;
		font-size: 22px;
		align-items: center;
		border-top: 1px solid $bg;
		border-bottom: 1px solid $bg;
		display: inline-block;
		padding: 11px 20px 10px;
		line-height: 1;
		position: relative;
		@include font-family('dc');
		background: $bg;
		height: 45px;
	}
	.left-triangle {
		&:before {
			transform: rotate(45deg);
	    position: absolute;
	    content: "";
	    top: 6px;
	    left: -16px;
	    width: 31px;
	    height: 31px;
			background: $bg;
		}
		span {
			position: absolute;
			left: -12px;
			top: 13px;
		}
		img {
			height: 19px;
			width: 19px;
		}
	}
	.right-triangle {
		&:before {
			transform: rotate(45deg);
	    position: absolute;
	    content: "";
	    top: 6px;
	    right: -16px;
	    width: 31px;
	    height: 31px;
			background: $bg;
		}
		span {
			position: absolute;
			right: -12px;
			top: 12px;
		}
		img {
			height: 19px;
			width: 19px;
		}
	}
}

// Hex Button menu
@mixin hex-btn-menu($bg, $color) {
	.tag {
		p {
			color: $color;
			font-size: 20px;
			padding-bottom: 5px;
			@include font-family('dc');
			@include media($mobile-only) {
				font-size: 26px;
			}
		}
	}
	.title {
		h4 {
			text-align: center;
			color: $color;
			font-size: 30px;
			@include font-family('ks-bold');
			align-items: center;
			border-top: 1px solid $color;
			border-bottom: 1px solid $color;
			display: inline-block;
			padding: 12px 5px 11px;
			position: relative;
			line-height: 35px;
			@include media($mobile-only) {
				font-size: 28px;
				padding: 15px 0px 15px;
				line-height: 1;
			}
		}
		.left-triangle {
			&:before {
				position: absolute;
				transform: rotate(-45deg);
				content: "";
				width: 43px;
				border-top: 1px solid $color;
				border-left: 1px solid $color;
				left: -22px;
				top: 8px;
				height: 42px;
			}
		}
		.right-triangle {
			&:before {
				position: absolute;
				transform: rotate(135deg);
				content: "";
				width: 43px;
				border-top: 1px solid $color;
				border-left: 1px solid $color;
				right: -22px;
				top: 8px;
				height: 42px;
			}
		}
	}
}

// -------------------------------------
//  Animations
// -------------------------------------

// -------------------------------------
//  Grid
// -------------------------------------
.single-post .grid-wrapper,
.blog .grid-wrapper,
.category .grid-wrapper,
.archive .grid-wrapper {
	@include outer-container;

	article {
		@include span-columns(7);
		@include shift(.5);

	}

	.sidebar {
		@include span-columns(3);
		@include shift(.5);
		@include omega();
	}

	.blog-pagination {
		clear: both;
	}
}
