/* Margin, Padding, */
$i: 50;
$j: 0;
@while $j <= $i {
  .pt-#{$j} {
    padding-top: #{$j}px;
  }
  .pb-#{$j} {
    padding-bottom: #{$j}px;
  }
  .pl-#{$j} {
    padding-left: #{$j}px;
  }
  .pr-#{$j} {
    padding-right: #{$j}px;
  }
  .p-#{$j} {
    padding: #{$j}px;
  }
  .px-#{$j} {
    padding-left: #{$j}px;
    padding-right: #{$j}px;
  }
  .py-#{$j} {
    padding-top: #{$j}px;
    padding-bottom: #{$j}px;
  }
  .mt-#{$j} {
    margin-top: #{$j}px;
  }
  .mb-#{$j} {
    margin-bottom: #{$j}px;
  }
  .ml-#{$j} {
    margin-left: #{$j}px;
  }
  .mr-#{$j} {
    margin-right: #{$j}px;
  }
  .m-#{$j} {
    margin: #{$j}px;
  }
  .mx-#{$j} {
    margin-right: #{$j}px;
    margin-left: #{$j}px;
  }
  .my-#{$j} {
    margin-top: #{$j}px;
    margin-bottom: #{$j}px;
  }
  $j: $j + 5;
}
/* margin auto */
.m-auto {
  margin: auto;
}
.no-gutter {
  margin: 0;
}

/* Float */
.f-none {
  float: none;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}

.fill-parent {
  @include fill-parent;
}

// Display
.display-table {
  @include row(table);
  // Context changed to table display
}
// @include display-context(table) {
//  .display-table {
//    @include span-columns(6);
//   }
// }

.display-block {
  @include reset-display;
}

// text-align
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

// Theme bg and text colors
.theme-teal {
  background: $theme-teal !important;
}
.theme-blue {
  background: $theme-blue !important;
}
.theme-green {
  background: $theme-green !important;
}
.theme-orange {
  background: $theme-orange !important;
}
.theme-yellow {
  background: $theme-yellow !important;
}
.theme-red {
  background: $theme-red !important;
}

.theme-teal-color {
  color: $theme-teal !important;
}
.theme-blue-color {
  color: #80C7DB !important;
}
.theme-green-color {
  color: $theme-green !important;
}
.theme-orange-color {
  color: $theme-orange !important;
}
.theme-yellow-color {
  color: #FFBB00 !important;
}
.theme-red-color {
  color: $theme-red !important;
}

.media-block {
  &:before {
    background-color: rgb(74, 74, 74);
    content: '';
    opacity: 0.3;
    @include position-absolute;
  }
  &.orange {
    &:before {
      background-color: $theme-orange !important;
    }
  }
  &.blue {
    &:before {
      background-color: $theme-blue !important;
    }
  }
  &.green {
    &:before {
      background-color: $theme-green !important;
    }
  }
  &.yellow {
    &:before {
      background-color: $theme-yellow !important;
    }
  }
  &.teal {
    &:before {
      background-color: $theme-teal !important;
    }
  }
}
// .teal {
//   background-color: rgba(111, 132, 99, 0.75);
// }
// .yellow {
//   background-color: rgba(217, 154, 42, 0.75);
// }
// .blue {
//   background-color: rgba(77, 148, 168, 0.75);
// }
// .orange {
//   background-color: rgba(206, 104, 67, 0.75);
// }
.slick-dots {
  li {
    margin: 0 3px;
    button {
      &:before {
        font-size: 6px;
        color: #4F748A;
      }
    }
    &.slick-active {
      button {
        &:before {
          font-size: 12px;
        }
      }
    }
  }
}
.slick-next {
  right: 0 !important;
}
.slick-prev {
  left: 0 !important;
}

// @include media($desktop) {
//
// }
// @include media($tablet) {
//
// }
// @include media($mobile-only) {
//
// }
