.highlight {
  position: relative;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #517589;
      opacity: 0.64;
    }
  }
  &__container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 55px 15px;
    @include media($tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 890px;
    }
  }

  &__image {
    width: 100%;
    @include media($tablet) {
      max-width: 250px;
    }
    img {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    @include media($tablet) {
      max-width: 560px;
    }
    * {
      color: #f7f4eb;
    }

    p {
      font-family: "ks-bold", serif;
      font-size: 24px;
      line-height: 1.5;
      letter-spacing: 0.035em;
    }

    a {
      color: $theme-orange;
    }
  }
}
