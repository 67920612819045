.carousel-wrapper {
  margin-bottom: 0;
  padding: 0 0 2rem;
  .container {
    position: relative;
  }
  @include media($mobile-only) {
    padding: 2rem 0;
  }
  .carousel-static-block {
    position: relative;
    z-index: 1;
    background: $theme-teal;
    top: 70px;
    left: 20px;
    width: 100%;
    max-width: 580px;
    padding: 20px 30px 40px 20px;

    @include media($mobile-only) {
      max-width: calc(100% - 20px);
      text-align: center;
      left: 0;
      top: 50px;
    }

    &.blue {
      background-color: #0a3955;
    }

    &.red {
      background-color: #934637;
      margin-left: auto;
    }

    &.right {
      right: 20px;
      left: inherit;
      margin-left: auto;
      @include media($mobile-only) {
        max-width: calc(100% - 20px);
        text-align: center;
        right: 10px;
        top: 50px;
      }
    }
    &__subtitle {
      margin-bottom: 10px;
      color: #f7f4ea;
    }

    &__title {
      margin-bottom: 15px;
      color: #f7f4ea;
    }

    &__content {
      color: #f7f4ea;
      & + .carousel-static-block__links {
        margin-top: 50px;
      }
    }

    &__links {
    }
  }

  .carousel-center {
    .slick-slide {
      // padding-top: 200px !important;
      &.slick-block {
        position: relative;
        padding: 10px;
        @include media($mobile-only) {
          padding: 10px 5px;
        }
      }
      // &.slick-active {
      //   .slick-content  {
      //     display: block !important;
      //   }
      // }
    }
    .slick-captions {
      p {
        color: #0a3955;
        @include font-family('ks-light');
        font-size: 14px;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1;

      @include media($portables) {
        display: none !important;
      }
    }

    .slick-prev {
      left: 0;
      width: 120px;
      height: 70px;
      &:before {
        font-size: 40px;
        content: url('img/icons/slider-arrow--left.svg');
        opacity: 1;
        text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
      }
    }

    .slick-next {
      right: 0;
      width: 120px;
      height: 70px;
      &:before {
        font-size: 40px;
        content: url('img/icons/slider-arrow--right.svg');
        opacity: 1;
        text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
      }
    }
  }
}
