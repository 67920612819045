///////////////////////////////////////////////////
//
// IMAGE GRID
//
///////////////////////////////////////////////////

.moduleWrapper .imageGrid {
  @include clearfix;
  margin: 0 auto;

  &_single {
    width: 25%;
    float: left;
    height: 25vw;
    overflow: hidden;
    position: relative;
    @include clearfix;

    @include media($portables) {
      width: 50%;
      height: 50vw;
      max-height: none;
    }

    &_imageWrap {
      @include absolute-size;
      @include background-cover;
    }

    &_content {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;

      &:hover {
      }
    }

    &-link {
      .imageGrid_single_imageWrap {
        transition: transform 0.3s;
        &:after {
          transition: all, 0.25s;
          @include absolute-size;
          @include background-cover;
          content: ' ';
          opacity: 0.6;
          background-image: linear-gradient(-180deg, rgba(46, 28, 22, 0.8) 63%, #934637 88%);
        }
      }

      &:hover {
        .imageGrid_single_imageWrap {
          transform: scale(1.1);
        }
      }
    }
  }
}
