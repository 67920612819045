///////////////////////////////////////////////////
//
// VIDEO
//
///////////////////////////////////////////////////

.video {

    &_player {
        display: block;
        height: auto;
        width: 100%;
    }
}

.video-playing {
	.video_control {
		&:after {
			display: none;
		}
	}
}