.pb-cards {
  position: relative;
  background-color: #f7f4ea;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: url('img/topo-background.png') no-repeat;
    mix-blend-mode: color-burn;
    background-size: cover;
  }
  &__container {
    position: relative;
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    @include media($tablet) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.cardGrid-columnsThree {
  .pb-cards__background {
    padding-bottom: 34.43%;
    @media screen and (min-width: 1220px) {
      height: 420px;
      padding: 0;
    }
  }
  .pb-card {
    @include media($tablet) {
      width: calc((100% - 40px) / 3);
    }
  }
}

.cardGrid-columnsTwo {
  .pb-cards__background {
    padding-bottom: 50%;
    @media screen and (min-width: 1220px) {
      height: 610px;
      padding: 0;
    }
  }
  .pb-card {
    @include media($tablet) {
      width: calc((100% - 20px) / 2);
    }
  }
}

.pb-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &--orange {
    .pb-card__image {
      &:before {
        background-color: #ce6843;
        opacity: 0.7;
      }
    }
  }

  &--green {
    .pb-card__image {
      &:before {
        background-color: #6f8463;
        opacity: 0.7;
      }
    }
  }

  &--yellow {
    .pb-card__image {
      &:before {
        background-color: #d99a2a;
        opacity: 0.7;
      }
    }
  }

  &--teal {
    .pb-card__image {
      &:before {
        background-color: #4d94a8;
        opacity: 0.7;
      }
    }
  }

  &--blue {
    .pb-card__image {
      &:before {
        background-color: #0a3955;
        opacity: 0.7;
      }
    }
  }

  &__image {
    position: relative;
    margin: 20px 0;
    box-shadow: 0 0 10px rgba(black, 0.5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 500ms ease;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-link {
      &:hover {
        .pb-card__image {
          &:before {
            opacity: 0.9;
          }
        }

        // .pb-ornate {
        //   &--orange {
        //     background-color: #ce6843;
        //     border-color: #ce6843;
        //     &:before,
        //     &:after {
        //       background-color: #ce6843;
        //       border-color: #ce6843;
        //     }
        //   }

        //   &--green {
        //     background-color: #6f8463;
        //     border-color: #6f8463;
        //     &:before,
        //     &:after {
        //       background-color: #6f8463;
        //       border-color: #6f8463;
        //     }
        //   }

        //   &--yellow {
        //     background-color: #d99a2a;
        //     border-color: #d99a2a;
        //     &:before,
        //     &:after {
        //       background-color: #d99a2a;
        //       border-color: #d99a2a;
        //     }
        //   }

        //   &--teal {
        //     background-color: #4d94a8;
        //     border-color: #4d94a8;
        //     &:before,
        //     &:after {
        //       background-color: #4d94a8;
        //       border-color: #4d94a8;
        //     }
        //   }
        // }
      }
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    p {
      margin-bottom: 35px;
      padding: 10px 10px 0;
    }
  }

  &__links {
    text-align: center;
  }
}

.pb-ornate {
  position: relative;
  display: inline-block;
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 0;
  padding: 23px 60px 21px;
  @include font-family('ks-bold');
  font-size: 18px;
  letter-spacing: 0.25px;
  line-height: 1.16;
  text-align: center;
  // border-top: 1px solid #f7f4ea;
  // border-bottom: 1px solid #f7f4ea;
  color: #f7f4ea;
  @media only screen and (min-width: 900px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 36px;
  }

  // &:before,
  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   width: 47px;
  //   height: 47px;
  //   transform: translateY(-50%) rotate(45deg);
  //   z-index: -1;
  // }

  // &:before {
  //   left: -24px;
  //   border-left: 1px solid #f7f4ea;
  //   border-bottom: 1px solid #f7f4ea;
  // }

  // &:after {
  //   right: -24px;
  //   border-right: 1px solid #f7f4ea;
  //   border-top: 1px solid #f7f4ea;
  // }

  span {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      transform: translateY(-50%);
      z-index: 1;
      pointer-events: none;
      @media only screen and (min-width: 900px) {
        width: 55px;
        height: 45px;
      }
    }

    &:before {
      left: 0;
      background: url('img/icons/button-decoration--left-white.svg') no-repeat center center;
      background-size: 100% auto;
    }

    &:after {
      right: 0;
      background: url('img/icons/button-decoration--right-white.svg') no-repeat center center;
      background-size: 100% auto;
    }
  }
}
