///////////////////////////////////////////////////
//
// Cards
//
///////////////////////////////////////////////////

/* Card grid */

// .moduleWrapper .cardGrid {
//     padding: 30px 100px 60px 100px;
//     @include media($mobile-only) {
//         padding: 20px 10px;
//     }
//     @include media($portables) {
//         padding: 30px 20px;
//     }
//     &_wrapper {
//         display: flex;
//         flex-flow: row wrap;
//         justify-content: flex-start;
//         margin: 0 auto;
//         max-width: 1020px;
//         width: 100%;

//         &_static {
//             margin: 0 auto 35px;
//             max-width: 1020px;
//             padding-left: 20px;
//             width: 100%;

//             h2 {
//                 margin-bottom: 0;
//             }

//             h3 {
//                 margin-bottom: 0;
//                 text-transform: uppercase;
//             }
//         }

//         &_single {
//             margin: 0 20px 20px 0;
//             position: relative;
//             @include media($mobile-only) {
//                 width: 100%;
//             }
//             @include media($portables) {
//                 width: calc((100% - 20px)/2);
//             }

//             &:last-of-type {
//                 margin-right: 0;
//             }

//             &:nth-of-type(2n) {
//                 @include media($portables) {
//                     margin-right: 0;
//                 }
//             }

//             &_content {
//                 padding: 20px 20px 56px 20px;

//                 &_title {
//                     margin-bottom:10px;
//                     font-size: 1.375rem;
//                     line-height: 1.27;
//                     @include media($desktop-only) {
//                         margin-bottom: 15px;
//                     }
//                 }

//                 .primary-button {
//                     position: absolute;
//                     bottom: 0;
//                     left: 20px;
//                     width: calc(100% - 40px);
//                 }
//             }

//             &_header {
//                 position: relative;
//                 &:after {
//                     content: '';
//                     display: block;
//                     padding-bottom: 100%;
//                 }

//                 &_image {
//                     @include absolute-size;
//                     @include background-cover;
//                 }
//             }

//             &_link {
//                 border: none;
//                 border-radius: 0;
//                 bottom: 0;
//                 color: white;
//                 cursor: pointer;
//                 display: inline-block;
//                 left: 0;
//                 line-height: 100% !important;
//                 padding: 10px;
//                 position: absolute;
//                 right: 0;
//                 text-align: center;
//                 text-decoration: none;
//                 z-index: 2;

//                 &:after {
//                     transition: opacity 0.3s;
//                     background-color: $black;
//                     content: '';
//                     height: 100%;
//                     left: 0;
//                     opacity: 0;
//                     position: absolute;
//                     top: 0;
//                     width: 100%;
//                     z-index: -1;
//                 }

//                 @include media($portables) {
//                     padding: 13px 0;
//                 }

//                 @include media($mobile-only) {
//                     padding: 11px 0;
//                 }

//                 &:hover {
//                     &:after {
//                         opacity: 0.2;
//                     }
//                 }
//             }
//         }
//     }

//     &-columnsOne {
//         .cardGrid_wrapper {
//             &_single {
//                 margin-right: 0;
//                 width: 100%;
//             }
//         }
//     }

//     &-columnsTwo {
//         .cardGrid_wrapper {
//             &_single {
//                 @include media($desktop-only) {
//                     width: calc((100% - 20px)/2);
//                 }
//                 @include media($desktop-1440) {
//                     width: calc((100% - 20px)/2);
//                 }
//                 &:nth-child(2n) {
//                     @include media($desktop-only) {
//                         margin-right: 0;
//                     }
//                     @include media($desktop-1440) {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }
//     }

//     &-columnsThree {
//         .cardGrid_wrapper {
//             &_single {
//                 @include media($desktop-only) {
//                     width: calc((100% - 20px*2)/3);
//                 }
//                 @include media($desktop-1440) {
//                     width: calc((100% - 20px*2)/3);
//                 }
//                 &:nth-child(3n) {
//                     @include media($desktop-only) {
//                         margin-right: 0;
//                     }
//                     @include media($desktop-1440) {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }
//     }

//     &-columnsFour {
//         .cardGrid_wrapper {
//             &_single {
//                 @include media($desktop-only) {
//                     width: calc((100% - 20px*3)/4);
//                 }
//                 @include media($desktop-1440) {
//                     width: calc((100% - 20px*3)/4);
//                 }
//                 &:nth-child(4n) {
//                     @include media($desktop-only) {
//                         margin-right: 0;
//                     }
//                     @include media($desktop-1440) {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }
//     }

//     &-columnsFive {
//         .cardGrid_wrapper {
//             @include media($desktop-1440) {
//                 @include clear;
//             }
//             &_single {
//                 @include media($desktop-only) {
//                     width: calc((100% - 20px*5)/5);
//                 }
//                 @include media($desktop-1440) {
//                     float: left;
//                     width: calc((100% - 20px*5)/5);
//                 }
//                 &:nth-child(5n) {
//                     @include media($desktop-only) {
//                         margin-right: 0;
//                     }
//                     @include media($desktop-1440) {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }
//     }
// }

// .moduleWrapper-cards + .moduleWrapper-cards {
//     margin-top: -20px;

//     @include media($desktop) {
//         margin-top: -30px;
//     }

//    .cardGrid {
//         padding-top: 0px;
//    }
// }
