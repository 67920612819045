.full-slider-wrapper {
  margin-bottom: 0;
  background: $theme-blue;
  padding: 2rem 0;
  -webkit-box-shadow: inset 0px 0px 20px 0 rgba(0,0,0,0.55);
  -moz-box-shadow: inset 0px 0px 20px 0 rgba(0,0,0,0.55);
  box-shadow: inset 0 0px 20px 0px rgba(0,0,0,0.55);
  @include media($mobile-only) {
    padding: 1rem 0;
  }
}
