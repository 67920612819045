///////////////////////////////////////////////////
//
// CONTACT FORM
//
///////////////////////////////////////////////////

.moduleWrapper .contactForm {
    padding: 20px;
    position: relative;
    overflow: hidden;
    z-index: 0;

    @include media($mobile-only) {
        padding: 20px 10px;
    }

    @include media($tablet) {
        padding: 40px 20px;
    }

    @include media($desktop) {
        padding-bottom: 70px;
        padding-top: 30px;
        padding-left: 100px;
        padding-right: 100px;
    }

    &_side_background {
        position: absolute;
        z-index: -1;

        @include media($mobile-only) {
            display: none;
        }

        @include media($tablet) {
            display: none;
        }

        @include media($desktop) {
            display: block;
            left: 0px;
            top: 0px;
            height: 100%;
            width: calc(31.76157% + 100px);
        }

        @include media($desktop-1400) {
            width: calc((100% - 1200px)/2 + 450px);
        }
    }

    &_wrapper {
        margin: auto;
        max-width: 1200px;

        @include media($mobile-only) {
            @include clearfix;
        }

        @include media($tablet) {
            @include clearfix;
        }

        @include media($desktop) {
            @include clearfix;
        }

        &_side {
            @include clearfix;

            @include media($mobile-only) {
                padding: 20px 10px 20px 10px;
                margin: -20px -10px 0 -10px;
            }

            @include media($tablet) {
                padding: 40px 20px 40px 20px;
                margin: -40px -20px 0 -20px;
            }

            @include media($desktop) {
                float: left;
                padding: 0;
                margin: 40px 0 0 0;
                @include span-columns(4 of 12);
            }

            &_content {
                p {
                    margin-bottom: 40px;
                }

                h1, h2, h3, h4, h5, h6, li, p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_form {

            @include media($mobile-only) {
                margin-top: 0;
            }

            @include media($tablet) {
                margin-top: 0;
            }

            @include media($desktop) {
                // @include span-columns(6 of 12);
                @include shift(1);
                padding-left: 30px;
            }

            @include media($desktop-1400) {
                margin-left: 0;
            }

            &_title {
                @include media($desktop) {
                    margin-bottom: 14px;
                }
            }

            form .gform_body ul {
                li {
                    .ginput_container {
                        margin-top: .125em !important;
                        input, textarea, select {
                            padding-left: 0.5em;
                            padding-right: 0.5em;
                            border: 2px solid;
                            width: 97.5%;
                        }

                        select {
                            padding-top: 0.8em;
                            padding-bottom: 0.8em;
                        }

                        textarea {
                            height: 8em;
                            padding-top: 0.313em;
                        }
                    }
                    .gfield_label {
                        // @include paragraph-font (17, 150, uppercase);
                      font-style: normal;
                      margin-bottom: 0;
                      font-size: 20px;
                      line-height: 30px;
                    }
                }
            }
        }
    }
}
