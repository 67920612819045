.footer {
  width: 100%;
  background: #0A3955;
  padding: 1.5rem 0;
  .footer-wrapper {
    .container {
      border-top: 2px dashed #F7F4EA;
      border-bottom: 2px dashed #F7F4EA;
      padding: 2rem 0;
    }
    .footer_logo {
      .logo-wrapper {
        img {
          @include media($mobile-only) {
            margin: auto;
          }
        }
      }
    }
    ul {
      @include media($mobile-only) {
        padding: 0 2rem 0 3.5rem;
      }
      li {
        line-height: 1.2;
        h4 {
          color: $white;
          padding-bottom: 15px;
          text-decoration: none;
          font-weight: bold;
          font-size: 30px;
          @include font-family('ks-bold');
        }
        a {
          color: $white;
          text-decoration: none;
          font-weight: bold;
          font-size: 20px;
          @include font-family('dc');
        }
      }
    }
    .footer-contact-details {
      margin-bottom: 20px;
      @include media($mobile-only) {
        padding: 2rem 2rem 0 3.5rem;
      }
      & * {
        color: #F7F4EA;
      }
      @include font-family('ks-bold');
      a {
        color: $white;
      }
      p {
        font-size: 16px;
        color: #F7F4EA;
        line-height: 1.4;
        @include font-family('ks-light');
        @include media($mobile-only) {
          font-size: 18px;
        }
      }
      h2 {
        font-size: 30px;
        padding-bottom: 15px;
        line-height: 35px;
        @include font-family('ks-bold');
      }
    }
    .social-media {
      a {
        display: inline-block;
        padding-right: 15px;
        img {
          height: 30px;
        }
      }
    }
  }
}
