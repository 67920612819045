// -------------------------------------
// Breakpoints
// -------------------------------------

$max-width: 60rem; // 960 / 16
$mobile-width: 767px;
$tablet-width: 768px;
$desktop-width: 991px;
$huge-width: 1440px;


// Breakpoints
$tablet: new-breakpoint(min-width $tablet-width 12); // (max-width #columns)
$desktop: new-breakpoint(min-width $desktop-width 12); // (max-width #columns)
$huge: new-breakpoint(min-width $huge-width 12); // (max-width #columns)

$mobile-only: new-breakpoint(max-width ($mobile-width - 1px), 12);
$tablet-only: new-breakpoint(min-width $tablet-width max-width ($desktop-width - 1px), 12);
$portables: new-breakpoint(max-width ($desktop-width - 1px), 12);
$desktop-only: new-breakpoint(min-width $desktop-width max-width ($huge-width - 1px), 12);

$visual-grid-color: lighten(#FFF0E8, 3%);

$desktop-1400: new-breakpoint(min-width 1400px 12);
$desktop-1440: new-breakpoint(min-width 1440px 12);

$visual-grid: true;
$visual-grid-color: #E6F6FF;
$visual-grid-opacity: 0.4;
