.title-module {
  @include pad(4rem 0);
  @include media($mobile-only) {
    @include pad(3rem 0);
  }
  .block {
    background: url("img/title-background.png") no-repeat;
    background-size: contain;
    background-position: center;
    .title {
      padding-bottom: 5px;
      h4 {
        color: $theme-teal;
        font-size: 28px;
        @include font-family("ks-bold");
        display: inline-block;
        border-bottom: 1px dashed #4e676e;
        position: relative;
        @include pad(5px 6rem 10px);
        @include btn-decoration(40px, 40px, teal, 0px, 0px, 0px);
        @include media($mobile-only) {
          @include pad(5px 45px 10px);
          font-size: 24px;
          // @include btn-decoration(10px, 10px, teal, 0px, 0px, 0px);
        }
      }
    }
    .content {
      padding-top: 3px;
      @include media($mobile-only) {
        padding-top: 10px;
      }
      p {
        color: $text;
        font-size: 20px;
        line-height: 1.4;
        @include font-family("ks-light");
        @include media($mobile-only) {
          line-height: 1.6;
          padding: 0 5px;
        }
      }
    }
  }

  .title-link {
    margin-top: 30px;
  }
}
