.image-grid {
  .cardGrid-columnsFour {
    @include media($mobile-only) {
      margin-right: 0;
      width: calc((100% * 6) / 12);
    }
  }
  .card {
    position: relative;
    transition: 1s solid ease-in;
    img {
      width: 100%;
    }
    .content {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      display: none;
      transition: 1s display ease;
      background-color: rgba(111, 132, 99, 0.55);
      &.teal {
        background-color: rgba(111, 132, 99, 0.55);
      }
      &.yellow {
        background-color: rgba(217, 154, 42, 0.55);
      }
      &.blue {
        background-color: rgba(77, 148, 168, 0.55);
      }
      &.orange {
        background-color: rgba(206, 104, 67, 0.55);
      }
      h4 {
        color: #FFF;
        text-align: center;
        font-size: 22px;
        align-items: center;
        border-top: 2px solid $theme-red;
        border-bottom: 2px solid $theme-red;
        display: inline-block;
        margin-top: calc(50% - 23px);
        padding: 10px 20px 11px;
        position: relative;
        background: $theme-orange;
        z-index: 1;
        @include btn-decoration-top-bottom(40px, 40px, orange, -80px, -80px, calc(50% - 20px));
        @include media($mobile-only) {
          font-size: 14px;
          padding: 7px 5px 8px;
          @include btn-decoration-top-bottom(30px, 30px, orange, -50px, -50px, calc(50% - 15px));
        }
      }
      .left-triangle {
        &:before {
          position: absolute;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          content: "";
          width: 44px;
          border-top: 2px solid $theme-red;
          border-left: 2px solid $theme-red;
          left: -23px;
          top: 7px;
          height: 44px;
          background: $theme-orange;
          z-index: -1;
          @include media($mobile-only) {
            width: 32px;
            border-top: 2px solid #934637;
            border-left: 2px solid #934637;
            left: -16px;
            top: 5px;
            height: 32px;
          }
        }
      }
      .right-triangle {
        &:before {
          position: absolute;
          transform: rotate(135deg);
          content: "";
          width: 44px;
          border-top: 2px solid $theme-red;
          border-left: 2px solid $theme-red;
          right: -23px;
          top: 7px;
          height: 44px;
          background: $theme-orange;
          z-index: -1;
          @include media($mobile-only) {
            width: 32px;
            border-top: 2px solid #934637;
            border-left: 2px solid #934637;
            right: -16px;
            top: 5px;
            height: 32px;
          }
        }
      }
    }
    &:hover {
      .content {
        display: block;
      }
    }
  }
}
