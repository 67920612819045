.latest-news {
  position: relative;
  margin-bottom: 70px;

  &__background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &:after {
      content: '';
      display: block;
      padding-bottom: 40.625%;
    }
  }

  &__callout {
    max-width: 880px;
    width: 100%;
    padding: 15px 35px;
    background-color: #4f748a;
    @include media($desktop) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -40px;
    }
    &-title {
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 15px;
      color: #0a3955;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        max-width: 185px;
        width: 100%;
        height: 1px;
        border-bottom: 3px dashed #f7f4ea;
      }
    }

    &-body {
      color: #f7f4ea;
    }

    &-links {
      text-align: right;
    }
  }

  &__bar {
    background: #4f748a;
    @include media($desktop) {
      margin-top: -80px;
    }
    @media only screen and (min-width: 1200px) {
      width: calc((100vw - 1200px) / 2 + 1200px);
      margin-left: calc((100vw - 1200px) / 2);
      padding-right: calc((100vw - 1200px) / 2);
    }
    &-container {
      @include media($tablet) {
        display: flex;
      }
    }

    &-item {
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
      padding: 10px 20px;
      min-height: 150px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        right: 0;
        width: 1px;
        height: calc(100% - 40px);
        border-right: 1px dashed #f7f4ea;
      }
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a8bac8;
        &:after {
          display: none;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &-intro {
        color: #0a3955;
      }

      time {
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 10px 2px;
        background-color: #f7f4ea;
        color: #0a3955;
        opacity: 1;
        @include font-family('dc');
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1;
      }

      a {
        color: #f7f4ea;
      }
    }
  }

  .recent-post {
    .block {
      position: relative;
      &:after {
        border-right: 1px dashed #fff;
        content: '';
        position: absolute;
        right: 0;
        height: calc(100% - 30px);
        top: 15px;
      }
    }
    &:first-child,
    &:last-child {
      .block {
        &:after {
          border: none;
        }
      }
    }
  }

  .block {
    background-color: #4f748a;
    position: relative;
    @include pad(1.5rem 1.5rem 1rem);
    @include media($mobile-only) {
      margin: 0 8px;
    }
    .title {
      text-align: left;
      padding-bottom: 5px;
      margin-bottom: 5px;
      h4 {
        color: $theme-dark-blue;
        font-size: 32px;
        letter-spacing: 0;
        @include font-family('ks-bold');
        display: inline-block;
        position: relative;
        padding-bottom: 5px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 120px;
          border-top: 1px dashed #fff;
        }
      }
    }
    .content {
      padding-top: 3px;
      @include media($mobile-only) {
        text-align: center;
      }
      p {
        color: #f7f4ea;
        font-size: 18px;
        line-height: 1.4;
        position: relative;
        text-align: left;
        @include font-family('ks-light');
        @include media($mobile-only) {
          font-size: 22px;
        }
      }
      .event-spaces {
        margin-right: 2rem;
        color: #fff;
        text-align: center;
        font-size: 16px;
        margin-top: 10px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        display: inline-block;
        background: $theme-dark-blue;
        padding: 0 30px;
        position: relative;
        height: 43px;
        line-height: 39px;
        @include media($mobile-only) {
          @include pad(8px 30px);
          margin-right: 0;
        }
        span {
          position: absolute;
          right: -12px;
          top: 12px;
        }
        &.blue {
          @include hex-btn-fill($theme-dark-blue, #fff);
          background: $theme-dark-blue;
        }
        &.red {
          @include hex-btn-fill(rgba(206, 104, 67, 1), #fff);
          background: rgba(206, 104, 67, 1);
        }
      }
      .left-triangle {
        &:before {
          position: absolute;
          transform: rotate(-45deg);
          content: '';
          width: 43px;
          left: -22px;
          top: 8px;
          height: 42px;
        }
      }
      .right-triangle {
        &:before {
          position: absolute;
          transform: rotate(135deg);
          content: '';
          width: 43px;
          right: -22px;
          top: 8px;
          height: 42px;
        }
      }
    }
  }
  .recent-news {
    position: relative;
    background-color: #4f748a;
    margin-left: calc((100% - 1200px) / 2);
    padding-right: calc((100% - 1200px) / 2);
    height: 150px;
    top: 75px;
    @include media($mobile-only) {
      margin-left: 0;
      padding-right: 0;
      background-color: transparent;
      top: 20px;
    }
    .recent-post {
      height: 150px;
      &:first-child {
        .block {
          background-color: #a8bac8;
          @include media($mobile-only) {
            border: none;
          }
          .title {
            padding-bottom: 0;
            margin-bottom: 0;
            top: calc(50% - 16px);
            position: relative;
            h5 {
              color: $theme-dark-blue;
              @include font-family('ks-bold');
              text-transform: capitalize;
              text-align: center;
              font-size: 26px;
              letter-spacing: 0;
            }
          }
        }
      }
      &:last-child {
        .block {
          @include media($mobile-only) {
            border: none;
          }
        }
      }
      .block {
        padding: 0.5rem 1rem;
        height: 150px;
        @include media($mobile-only) {
          border-bottom: 2px dashed #fff;
          padding: 1rem;
        }
        .content {
          padding-top: 0;
          @include media($mobile-only) {
            text-align: left;
            a {
              p {
                font-size: 24px;
                margin-top: 8px;
              }
            }
          }
        }
      }
      p {
        @include font-family('dc');
        margin-top: 5px;
        font-size: 20px;
        line-height: 1.2;
      }
      label {
        background: #ffffff;
        color: #2b2b2b;
        font-size: 12px;
        padding: 2px 5px;
      }
    }
  }
}
