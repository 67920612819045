.wysiwyg {
  @include pad(4rem 0);
  .block {
    .content {
      // padding-top: 3px;
      h2 {
        color: $theme-teal;
        font-size: 28px;
        @include font-family('ks-bold');
        position: relative;
        margin-bottom: 5px;
        line-height: 1.3;
      }
      h4, span.pullQuote {
        color: $theme-teal;
        @include font-family('ks-bold');
        font-size: 26px;
        position: relative;
        margin-bottom: 5px;
        line-height: 1.3;
        padding-right: 15px;
      }
      p {
        margin-bottom: 10px;
        color: $text;
        font-size: 16px;
        line-height: 1.4;
        @include font-family('ks-light');
        b, strong {
          color: $theme-teal;
          @include font-family('ks-bold');
          font-size: 18px;
          position: relative;
          margin-bottom: 5px;
          line-height: 1.3;
          padding-right: 15px;
        }
      }
      p[class*='col-'], h4[class*='col-'] {
        padding-right: 15px;
      }
      img {
        padding: 15px;
        width: 280px;
      }
    }
  }
}
