///////////////////////////////////////////////////
//
// FOOTER
//
///////////////////////////////////////////////////

.footer {
    &_menu {
        padding: 15px;

        @include media($desktop) {
            padding: 30px 15px;
        }

        &_wrapper {
            @include media($mobile-only) {
                padding: 15px 15px 0 15px;
            }

            @include media($desktop) {
                padding: 0 40px;
            }

            @include media($tablet) {
                margin: auto;
                max-width: 1200px;
            }

            &_single, &_button {
                @include media($tablet) {
                    display: inline-block;
                    margin-right: 20px;
                    position: relative;
                }

                &:after {
                    @include media($tablet) {
                        content: '|';
                        position: absolute;
                        right: -2px;
                        top: 0;
                    }
                }

                &:first-child {
                    @include media($tablet) {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    &:after {
                        @include media($tablet) {
                            display: none;
                        }
                    }
                }
            }

            &_single {
                @include head-font (20, 40, uppercase);
                color: $black;
                font-weight: 600;

                @include media($desktop) {
                    padding: 0 20px;
                }

                &_link {
                    color: $black;
                    margin-right: 30px;
                    text-decoration: none;
                }
            }

            &_button {
                margin-bottom: 10px;

                @include media($mobile-only) {
                    display: table;
                }

                &:first-child {
                    margin-top: 5px;
                }

                &:last-child {
                    margin-bottom: 20px;
                }

                &:after {
                    @include media($tablet) {
                        content: '';
                    }
                }

                a {
                    text-align: center;
                    width: 100%;
                }
            }
        }

        &_button {
            @include media($tablet-only) {
                margin: auto;
                max-width: 1200px;
                padding: 15px 15px 0 15px;
            }

            @include media($desktop) {
                padding: 10px 15px;
                margin: 0 auto;
                max-width: 1200px;
                text-align: center;
            }

            &_wrapper {
                @include media($desktop) {
                    padding: 0 40px;
                }

                &_single {
                    @include media($desktop) {
                        padding: 0 40px;
                    }
                }
            }
        }
    }

    &_menuBottom {
        padding: 15px;

        @include media($desktop) {
            padding: 10px 15px;
            text-align: center;
        }

        @include media($huge) {
            padding: 15px 15px;
        }

        &_wrapper {
            @include media($tablet) {
                margin: auto;
                max-width: 1200px;
            }

            &_single, &_button {
                @include media($tablet) {
                    display: inline-block;
                    margin-right: 20px;
                    position: relative;
                }

                &:after {
                    @include media($tablet) {
                        content: '|';
                        position: absolute;
                        right: -2px;
                        top: 0;
                    }
                }

                &:first-child {
                    @include media($tablet) {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    &:after {
                        @include media($tablet) {
                            display: none;
                        }
                    }
                }
            }

            &_button {
                &:after {
                    @include media($tablet) {
                        content: '';
                    }
                }
            }

            &_single {
                @include head-font (20, 40, uppercase);
                color: $white;

                &_link {
                    color: $white;
                    margin-right: 30px;
                    text-decoration: none;

                    @include media($desktop) {
                        font-size: 14px;
                    }

                    @include media($huge) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &_social {
        @include clearfix;
        margin: auto;
        max-width: 1200px;
        padding: 15px 15px 0 15px;

        &_icon {
            float: left;
            display: inline-block;
            margin-top: 5px;
            margin-right: 15px;
            text-decoration: none;
            font-size: 20px;

            @include media($desktop) {
                transform: translateY(5px);
                font-size: 30px;
                margin-right: 0;
            }

            @include media($huge) {
                margin-top: 10px;
            }

            &:before {
                @include icon('');
            }

            span {
                display: block;
                left: -999999px;
                position: absolute;
                top: 0;
            }

            &-dribbble {
                &:before {
                    content: $icon-dribbble;
                }
            }

            &-facebook {
                &:before {
                    content: $icon-facebook;
                }
            }

            &-instagram {
                &:before {
                    content: $icon-instagram;
                }
            }

            &-linkedin {
                &:before {
                    content: $icon-linkedin;
                }
            }

            &-twitter {
                &:before {
                    content: $icon-twitter;
                }
            }

            &-gplus {
                @include media($desktop) {
                    font-size: 38px;
                    margin-top: 6px;
                }
                &:before {
                    content: $icon-gplus;
                }
            }

            &-youtube {
                &:before {
                    content: $icon-youtube;
                }
            }
        }

        &_logo, &_logo_button {
            @include media($mobile-only) {
                width: 50%;
                margin: 20px 0;
            }

            @include media($tablet) {
                display: block;
                float: left;
                height: 40px;
                margin-right: 50px;
                width: auto;
            }

            @include media($desktop) {
                display: block;
                float: left;
                height: 60px;
                margin-right: 50px;
                width: auto;
            }
        }

        &_logo_button {
            @include media($desktop) {
                float: none;
                margin: 0 auto;
                max-width: 600px;
                max-height: 200px;
                width: auto;
                height: auto;
                margin-bottom: 30px;
            }

            @include media($tablet) {
                width: 40%;
                height: auto;
                margin: 30px 0;
            }
        }

        .footer-socialHeader & {
            &_icon {
                display: none;

                @include media($mobile-only) {
                    display: block;
                }
            }
        }
    }
}


.topBar {
    .footer_social {
        &_icon {
            @include media($desktop) {
                font-size: 18px;
                margin-right: 22px;
            }

            @include media($huge) {
                font-size: 22px;
                margin-right: 22px;
            }

            &-gplus {
                @include media($desktop) {
                    font-size: 23px;
                    margin-top: 9px;
                }

                @include media($huge) {
                    font-size: 27px;
                    margin-top: 9px;
                }
            }

            &-twitter {
                @include media($desktop) {
                    font-size: 20px;
                }

                @include media($huge) {
                    font-size: 24px;
                }
            }
        }
    }
}

footer.footer-socialFooter.footer-button {
    .footer_social {
        text-align: center;

        a {
            float: none;
            margin-bottom: 30px;
        }
    }
}
