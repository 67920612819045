// *************************************
//
//   App
//   Use for Project Specific Modifications
//
// *************************************

// -------------------------------------
//   Reusable Sections/Snippets
// -------------------------------------

// Theme global
@import 'global';

.clear {
  @include clear;
}
.grid-wrapper {
	@include outer-container;
}
.horiz-center {
	@include center-horiz;
}
.vertical-center {
	@include center-vertically;
}
.img-fill {
	@include img-fill;
}
.img-glide {
	@include img-glide;
}


// -------------------------------------
//   Pages Level Modifications
// -------------------------------------
