// *************************************
//
//   Base
//   -> Base-level tags (body, h, p, etc.)
//
// *************************************

$Icons: 'Icons', sans-serif;

$base-font-size: 16px;
$base-line-height: 24px;

@font-face {
  font-family: 'dc';
  src: url('fonts/DINCondensed-Bold.woff2') format('woff2'),
    url('fonts/DINCondensed-Bold.woff') format('woff'),
    url('fonts/DINCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'ks-book';
  src: url('fonts/KlinicSlab-Book.woff2') format('woff2'),
    url('fonts/KlinicSlab-Book.woff') format('woff'),
    url('fonts/KlinicSlab-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ks-bold';
  src: url('fonts/KlinicSlab-Bold.woff2') format('woff2'),
    url('fonts/KlinicSlab-Bold.woff') format('woff'),
    url('fonts/KlinicSlab-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'ks-medium';
  src: url('fonts/KlinicSlab-Medium.woff2') format('woff2'),
    url('fonts/KlinicSlab-Medium.woff') format('woff'),
    url('fonts/KlinicSlab-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'ks-light';
  src: url('fonts/KlinicSlab-Light.woff2') format('woff2'),
    url('fonts/KlinicSlab-Light.woff') format('woff'),
    url('fonts/KlinicSlab-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: $base-font-size;
  line-height: $base-line-height;
  // font-family: 'dc';
}
body {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// size, tracking, transform
@mixin font($size, $ls, $trans) {
  text-transform: $trans;
  letter-spacing: $ls * 0.001em;

  font-size: $size * 0.65px;
  line-height: $size * 0.65px + 10px;

  @include media($tablet) {
    font-size: $size * 0.8px;
    line-height: $size * 0.8px + 10px;
  }
  @include media($desktop) {
    font-size: $size * 0.85px;
    line-height: $size * 0.85px + 10px;
  }
  @include media($huge) {
    font-size: $size * 1px;
    line-height: $size + 10px;
  }
}

@mixin head-font($size, $ls, $trans) {
  text-transform: $trans;
  letter-spacing: $ls * 0.001em;
  font-size: $size * 0.55px;
  line-height: $size * 0.55px + 10px;

  @include media($tablet) {
    font-size: $size * 0.7px;
    line-height: $size * 0.7px + 10px;
  }

  @include media($desktop) {
    font-size: $size * 0.85px;
    line-height: $size * 0.85px + 10px;
  }

  @include media($huge) {
    font-size: $size * 1px;
    line-height: $size + 10px;
  }
}

@mixin paragraph-font($size, $ls, $trans) {
  text-transform: $trans;
  letter-spacing: $ls * 0.001em;
  font-size: $size * 0.75px;
  line-height: $size * 0.75px + 10px;

  @include media($tablet) {
    font-size: $size * 0.85px;
    line-height: $size * 0.85px + 10px;
  }

  @include media($desktop) {
    font-size: $size * 0.9px;
    line-height: $size * 0.9px + 10px;
  }

  @include media($huge) {
    font-size: $size * 1px;
    line-height: $size * 1px + 10px;
  }
}

@mixin default-spacing() {
  margin-bottom: 10px;

  @include media($tablet) {
    margin-bottom: 15px;
  }

  @include media($desktop) {
    margin-bottom: 10px;
  }

  @include media($huge) {
    margin-bottom: 25px;
  }
}

@mixin wrapper {
  margin: auto;
  max-width: 792px;
}

// -------------------------------------
//   Text Sizes
// -------------------------------------

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ul,
ol {
  // @include default-spacing ();
}

// size, tracking, transform

h1,
.h1 {
  @include head-font(60, 90, none);
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
}

h2,
.h2 {
  @include head-font(40, 150, uppercase);
}

h3,
.h3 {
  @include font(30, 160, uppercase);
}

h4,
.h4 {
  @include font(25, 150, none);
}

h5,
.h5 {
  @include font(23, 150, uppercase);
}

h6,
.h6 {
  @include paragraph-font(17, 150, uppercase);
}

blockquote,
.blockquote {
  p {
    @include font(25, 50, none);
    font-style: italic;
  }
}

span.pullQuote {
  @include font(25, 50, none);
  font-style: italic;
}

p,
ol,
ul,
li {
  @include paragraph-font(18, 40, none);

  &.big {
    @include font(25, 40, none);
  }
}

// -------------------------------------
//	Links
// -------------------------------------

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

ol,
ul {
  list-style-position: inside;
  li {
    list-style: none;
  }
}

b {
  font-weight: bold;
}

body {
  background-color: #f7f4ea;
}
