.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  .header-wrapper {
    display: inline-block;
    width: 100%;
    // transition: 1s all ease;
    &.show {
      background: $primary;
    }
    .nav-bar {
      background: $primary;
      float: right;
      height: 50px;
      padding: 0px 15px 0 30px;
      @media screen and (max-width: 991px) {
        float: right;
        padding: 0;
        width: 100%;
        text-align: right;
        position: fixed;
        top: 0;
        z-index: 99;
      }
      li {
        display: inline-block;
        line-height: 36px;
        padding: 0 10px;
        @include font-family('dc');
        @media screen and (max-width: 991px) {
          padding: 0 10px 0 0;
        }
        a {
          color: #FFF;
          text-decoration: none;
          font-weight: bold;
          font-size: 26px;
          @include media($mobile-only) {
            right: -7px;
            position: relative;
            top: -1px;
          }
        }
        button {
          color: #FFF;
          padding-left: 10px;
          background: transparent;
          border: none;
          font-weight: 600;
          font-size: 28px;
          height: 50px;
          width: 110px;
          @media screen and (max-width: 991px) {
            line-height: 2;
            padding-left: 0;
          }
        }
        .hamburger {
          width: 28px;
          display: inline-block;
          position: relative;
          top: -2px;
          @media screen and (max-width: 991px) {
            top: 0;
          }
          .line {
            width: 20px;
            height: 2px;
            margin-bottom: 4px;
            background: #FFF;
            @media screen and (max-width: 991px) {
              width: 25px;
              height: 4px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .mega-menu {
      position: fixed;
      transition: 1s all ease;
      top: 50px;
      z-index: 99;
      width: 100%;
      background: $theme-dark-blue;
      display: none;
      @media screen and (max-width: 991px) {
        overflow: auto;
        position: relative;
        z-index: 1;
        height: calc(100vh - 50px);
      }
      &.show {
        display: block;
      }
      .mega-menu-wrapper {
        .menu-links {
          padding: 75px 0 150px 0;
          @media screen and (max-width: 991px) {
            padding: 35px 20px 0px 20px;
          }
          ul {
            @media screen and (max-width: 991px) {
              margin-bottom: 1.5rem;
            }
            li {
              line-height: 1.2;
              @media screen and (max-width: 991px) {
                line-height: 1.4;
              }
              h4 {
                color: $white;
                padding-bottom: 15px;
                text-decoration: none;
                font-weight: bold;
                font-size: 32px;
                @include font-family('ks-bold');
                @media screen and (max-width: 991px) {
                  padding-bottom: 10px;
                  font-size: 28px;
                }
              }
              a {
                color: $white;
                text-decoration: none;
                font-weight: bold;
                font-size: 20px;
                @include font-family('dc');
                @media screen and (max-width: 991px) {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .menu-bottom {
          background: #4F748A;
          color: #F7F4EA;
          margin-bottom: 20px;
          padding: 20px 0;
          @include font-family('ks-bold');
          @media screen and (max-width: 991px) {
            .col-12 {
              padding: 0px 20px 10px 20px;
            }
          }
          a {
            color: $white;
          }
          h4 {
            font-size: 20px;
            line-height: 1.5;
          }
          p {
            font-size: 18px;
          }
          h2 {
            font-size: 34px;
            @include font-family('ks-bold');
          }
        }
      }
    }
  }
}
