.contact-us {
  .row {
    display: flex;
    @include media($mobile-only) {
      display: block;
    }
  }
  .contact_block_left {
    // background-color: #A5B3B9;
    background: url("img/building-fade-out.png") #a5b3b9 no-repeat;
    background-position: center bottom;
    background-size: 100%;
    padding: 4rem 5rem;
    @include media($mobile-only) {
      padding: 2rem 1rem;
      background: lighten(#a8bac8, 5%) !important;
    }
    @include media($tablet-only) {
      padding: 2rem 1rem;
    }
    .block {
      width: 80%;
      margin: auto;
      @include media($mobile-only) {
        width: 100%;
      }
      @include media($tablet-only) {
        width: 90%;
      }
    }
    .title {
      padding-bottom: 5px;
      margin-bottom: 1rem;
      h4 {
        color: $white;
        font-size: 22px;
        letter-spacing: 0;
        @include font-family("ks-bold");
        display: inline-block;
        position: relative;
        @include pad(10px 3rem);
        @include media($mobile-only) {
          font-size: 30px;
          line-height: 1.3;
          padding: 10px 5px;
        }
        @include media($tablet-only) {
          padding: 10px 1rem;
        }
        &:before {
          position: absolute;
          content: "";
          width: 100%;
          left: 0;
          top: 0;
          border-top: 1px dashed #934637;
        }
        &:after {
          position: absolute;
          content: "";
          width: 50%;
          left: 25%;
          bottom: 0;
          border-top: 1px dashed #ce6843;
        }
      }
    }
    .content {
      padding-top: 0.625rem;
      text-align: left;
      width: 100%;
      // margin-left: 27%;
      // text-align: center;
      @include media($mobile-only) {
        width: 100%;
        margin-left: 0;
      }
      @include media($tablet-only) {
        width: 90%;
        margin-left: 5%;
      }
      p {
        color: #353535;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 1rem;
        @include font-family("ks-light");
      }
    }
  }
  .contact_block_right {
    background-color: #a8bac8;
    padding: 4rem 5rem;
    @include media($mobile-only) {
      padding: 3rem 1rem;
    }
    @include media($tablet-only) {
      padding: 2rem 3rem;
    }
    .contactForm_wrapper_form {
      // width: 80%;
      .contactForm_wrapper_form_title {
        @include font-family("ks-blod");
        letter-spacing: 0;
        font-size: 26px;
        margin-bottom: 5px;
        color: #2e1c16;
        @include media($mobile-only) {
          font-size: 30px;
          line-height: 1.5;
          margin-bottom: 1rem;
          text-align: center;
        }
      }
      .gfield_label {
        color: #353535;
        margin-bottom: 2px;
        font-style: normal;
        @include font-family("ks-light");
      }
      .ginput_container {
        margin-bottom: 15px;
      }
      .gform_wrapper {
        width: 80%;
        @include media($mobile-only) {
          width: 100%;
        }
        @include media($tablet-only) {
          width: 100%;
        }
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="tel"],
        input[type="file"],
        textarea {
          border: 1px solid rgba(46, 28, 22, 0.46) !important;
          border-radius: 15px !important;
          background-color: rgba(144, 121, 115, 0.04) !important;
        }
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="tel"],
        input[type="file"] {
          height: 46px !important;
        }
      }
      .ginput_container_fileupload {
        input[type="file"] {
          padding: 14px 15px;
          font-size: 12px;
          color: #333333;
        }
        span {
          font-size: 12px;
          color: #333333;
        }
      }
      .gform_footer {
        display: inline-block;
        position: relative;

        img {
          height: 16px;
          top: 15px;
          z-index: 1;
          position: absolute;
          &.left-arrow {
            left: 3px;
          }
          &.right-arrow {
            right: 3px;
          }
        }
        .button {
          background-color: transparent;
          border-radius: 0;
          border-width: 0 !important;
          text-transform: capitalize;
          font-size: 18px;
          z-index: 1;
          width: 200px;
          padding: 10px 0;
          height: 46px;
          top: 1px;
          @include font-family("dc");
          background: #ce6843;
        }
      }
    }
  }
}

.gsection_title {
  color: #2e1c16 !important;
  @include font-family("ks-bold");
}

.tripleseat-form {
  #tripleseat_embed_form {
    table {
      width: 100%;
      td {
        padding: 0;
      }

      tr {
        td {
          padding-right: 30px;
          padding-bottom: 15px;
          &:last-child {
            padding-right: 0;
          }
        }
        &.row_phone_number {
          #lead_phone_number {
            width: 100%;
          }
        }
      }
    }

    h2 {
      margin: 15px 0 0;
      @include font-family("ks-bold");
      letter-spacing: 0;
      font-size: 26px;
      color: #2e1c16;
      @include media($mobile-only) {
        font-size: 30px;
        line-height: 1.5;
        text-align: center;
      }
    }

    label {
      display: block;
      @include font-family("ks-light");
      font-size: 20px;
      line-height: 30px;
      color: #353535;
    }

    input,
    select,
    textarea {
      border: 1px solid rgba(46, 28, 22, 0.46);
      border-radius: 15px;
      background-color: rgba(144, 121, 115, 0.04);
      background-position: 10px center;
      width: 100%;
      height: 46px;
      padding: 9px 30px;
      line-height: 1.5;
      @include font-family("ks");
      color: #5f5f5f;
    }

    select,
    #lead_start_time,
    #lead_end_time,
    #dp_lead_event_date {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAgMTUiPiAgPHBvbHlnb24gcG9pbnRzPSI3LjUsMTEuMiAwLDMuOCAxNSwzLjgiIGZpbGw9IiMzNTM1MzUiLz48L3N2Zz4=)
        no-repeat right center;
      background-size: auto 15px;
    }

    textarea {
      resize: vertical;
      height: auto;
    }

    #lead_submit {
      border-radius: 0;
      border-width: 0 !important;
      width: auto;
      min-width: 200px;
      height: 46px;
      padding: 10px 0;
      @include font-family("dc");
      text-transform: capitalize;
      font-size: 18px;
      line-height: 1;
      color: white;
      background: #ce6843;
      z-index: 1;
    }

    #lead_form_submit a {
      color: white;
    }
  }
}
