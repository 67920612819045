.interior {
  @include media($mobile-only) {
    margin-top: 50px;
  }
  &-wrapper {
    &--minimal {
      position: relative;
      min-height: 500px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 50%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(81, 117, 137, 1) 0%,
          rgba(81, 117, 137, 0) 100%
        );
        z-index: 1;
        pointer-events: none;
      }
      .interior-wrapper__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: initial;
        &:after {
          display: none;
        }
      }
    }
    &__background {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      max-height: 650px;
      &:after {
        content: "";
        display: block;
        padding-bottom: 48.4375%;
      }
    }

    &__content {
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      padding: 25px 50px;
      text-align: center;
      @include media($desktop) {
        margin: -175px auto 0;
        padding: 25px 100px 25px;
      }
      &--teal {
        background-color: #4e676e;
      }
      &--yellow {
        background-color: #d99a2a;
      }
      &--blue {
        background-color: #4d94a8;
      }
      &--green {
        background-color: #6f8463;
      }
      &--orange {
        background-color: #ce6843;
      }

      &--no-body {
        margin-top: -100px;
      }

      &-title {
        color: #f7f4ea;
        text-shadow: 0 0 25px rgba(#000000, 0.5);
        & + .interior-wrapper__content-body {
          margin-top: 25px;
        }
      }

      &-body {
        color: #f7f4ea;
        &-link {
          margin-top: 25px;
        }
      }
    }

    &__minimal {
      position: relative;
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      padding: 50px 15px;
      z-index: 2;
    }
  }
}
