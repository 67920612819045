///////////////////////////////////////////////////
//
// BUTTONS
//
///////////////////////////////////////////////////

.button {
  transition: all 0.25s;

  @include font(20, 150, uppercase);
  line-height: 100% !important;
  padding: 16px 36px;
  border-radius: 90px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-width: 2px;
  border-style: solid;

  @include media($portables) {
    padding: 13px 30px;
  }

  @include media($mobile-only) {
    padding: 11px 26px;
  }

  &.primary {
    z-index: 1;
    border: none;

    &:after {
      content: ' ';
      transition: all 0.25s;
      @include font(20, 150, uppercase);
      line-height: 100% !important;
      padding: 17px 37px;
      border-radius: 90px;
      color: white;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      position: relative;
      //border-width: 2px;
      border: none;
      border-style: solid;
      background-color: $black;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-color: $black;
      opacity: 0;
      z-index: -1;

      @include media($portables) {
        padding: 13px 30px;
      }

      @include media($mobile-only) {
        padding: 11px 26px;
      }
    }

    &:hover {
      &:after {
        opacity: 0.2;
      }
    }
  }
}

.button.primary,
.button.secondary {
  text-shadow: none;
}

.pb-button {
  position: relative;
  display: inline-block;
  max-width: calc(100% - 40px);
  // min-width: 190px;
  margin: 0 20px;
  padding: 13px 25px 8px;
  @include font-family('dc');
  font-size: 24px;
  letter-spacing: 0.021em;
  line-height: 1;
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }

  &:before {
    left: -16px;
  }

  &:after {
    right: -16px;
  }

  span {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 15px;
      height: 15px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 1;
    }

    &:before {
      left: -5px;
      border-left: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    &:after {
      right: -5px;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
    }
  }

  &--orange {
    background-color: #ce6843;
    color: #f7f4ea;
    &:before,
    &:after {
      background-color: #ce6843;
    }
    span {
      &:before,
      &:after {
        border-color: #934637;
      }
    }
  }

  &--blue {
    background-color: #0a3955;
    color: #f7f4ea;
    &:before,
    &:after {
      background-color: #0a3955;
    }
    span {
      &:before,
      &:after {
        border-color: #4f748a;
      }
    }
  }

  &--offwhite {
    background-color: #f7f4ea;
    color: #0a3955;
    &:before,
    &:after {
      background-color: #f7f4ea;
    }
    span {
      &:before,
      &:after {
        border-color: #4f748a;
      }
    }
  }

  &--green {
    background-color: #6f8463;
    color: #f7f4ea;
    &:before,
    &:after {
      background-color: #6f8463;
    }
    span {
      &:before,
      &:after {
        border-color: #934637;
      }
    }
  }

  &--yellow {
    background-color: #d99a2a;
    color: #f7f4ea;
    &:before,
    &:after {
      background-color: #d99a2a;
    }
    span {
      &:before,
      &:after {
        border-color: #934637;
      }
    }
  }

  &--teal {
    background-color: #4d94a8;
    color: #f7f4ea;
    &:before,
    &:after {
      background-color: #4d94a8;
    }
    span {
      &:before,
      &:after {
        border-color: #934637;
      }
    }
  }
}

$font-family--primary: 'KlinicSlab', sans-serif;
$font-family--secondary: 'DINCondensed', sans-serif;

.pb-h1 {
  margin: 0;
  @include font-family('ks-bold');
  font-size: 42px;
  font-weight: 700;
  letter-spacing: -0.017em;
  line-height: 1.16;
  text-transform: none;
  @include media($desktop) {
    font-size: 60px;
  }
}

.pb-h2 {
  margin: 0;
  @include font-family('ks-bold');
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
  text-transform: none;
}

.pb-h3 {
  margin: 0;
  @include font-family('ks-bold');
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.007em;
  line-height: 1.16;
  text-transform: none;
}

.pb-h4 {
  margin: 0;
  @include font-family('dc');
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.013em;
  line-height: 1.13;
  text-transform: none;
}

.pb-h5 {
  margin: 0;
  @include font-family('dc');
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.015em;
  line-height: 1.08;
  text-transform: none;
}

.pb-h6 {
  margin: 0;
  @include font-family('dc');
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.013em;
  line-height: 1.06;
  text-transform: none;
}

.pb-p {
  margin: 0;
  @include font-family('ks-book');
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.5;
  color: #353535;
  opacity: 0.7;
  &--large {
    font-size: 20px;
  }

  &--small {
    font-size: 14px;
  }
}
