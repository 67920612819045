.blog-landing {
  margin-top: 8.5rem;
  position: relative;
  @media screen and (max-width: 991px) {
    margin-top: 4.5rem;
    padding: 0 10px;
  }
  .blog-listing {
    margin-bottom: 1.5rem;
    @media screen and (max-width: 991px) {
      margin-top: 6rem;
    }
  }
  .blog-block {
    margin-bottom: 2.5rem;
    .media {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      img {
        -ms-flex-item-align: center !important;
        align-self: center !important;
        vertical-align: middle;
        max-height: 230px;
        margin-right: 1.5rem;
      }
      .media-body {
        align-self: center !important;
        -ms-flex: 1;
        flex: 1;
        h5 {
          color: $text;
          @include font-family('dc');
          font-size: 24px;
          margin-bottom: 5px;
        }
        p {
          color: #424242;
          line-height: 1.3;
          color: #438192;
          @include font-family('ks-light');
        }
      }
    }
  }
}
.pagination {
  label {
    @include font-family('dc');
    color: #438192;
    padding: 2px 5px;
    &.active {
      background: #438192;
      color: #fff;
      border-radius: 9999px;
      padding: 6px 9px 2px;
    }
    a {
      color: #438192;
    }
  }
  .pages {
    label {
    }
  }
}

.news {
  @include media($tablet) {
    display: flex;
  }
  &__content {
    @include media($tablet) {
      width: calc((100vw - 1200px) / 2 + 810px);
      padding-left: calc((100vw - 1200px) / 2);
    }
    &-container {
      padding: 50px 20px 50px 10px;
    }

    &-wysiwyg {
      h1 {
        margin: 0 0 15px;
        @include font-family('ks-bold');
        font-size: 60px;
        font-weight: 700;
        letter-spacing: -0.017em;
        line-height: 1.16;
        text-transform: none;
        color: #353535;
      }

      h2 {
        margin: 0 0 15px;
        @include font-family('ks-bold');
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.2;
        text-transform: none;
        color: #353535;
      }

      h3 {
        margin: 0 0 15px;
        @include font-family('ks-bold');
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0.007em;
        line-height: 1.16;
        text-transform: none;
        color: #353535;
      }

      h4 {
        margin: 0 0 15px;
        @include font-family('dc');
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0.013em;
        line-height: 1.13;
        text-transform: none;
        color: #353535;
      }

      h5 {
        margin: 0 0 15px;
        @include font-family('dc');
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.015em;
        line-height: 1.08;
        text-transform: none;
        color: #353535;
      }

      h6 {
        margin: 0 0 15px;
        @include font-family('dc');
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.013em;
        line-height: 1.06;
        text-transform: none;
        color: #353535;
      }

      p,
      ul,
      ol {
        margin: 0 0 15px;
        @include font-family('ks-book');
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 1.5;
        color: #353535;
        opacity: 0.7;
        &.large {
          font-size: 20px;
        }

        small {
          font-size: 14px;
        }
      }
    }
  }

  &__sidebar {
    background-color: #4d94aa;
    @include media($tablet) {
      width: calc((100vw - 1200px) / 2 + 390px);
      padding-right: calc((100vw - 1200px) / 2);
    }
    &-container {
      padding: 50px 10px 50px 6.25vw;
    }

    ul {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
      li {
        &.title {
          @include font-family('dc');
          color: #ffffff;
        }
        a {
          color: #ffffff;
          @include font-family('ks-light');
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.post {
  margin-bottom: 40px;
  &__container {
    display: block;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    &:hover {
      .post__content-date,
      .post__content-title,
      .post__content-body {
        color: #4d94aa;
      }
    }
  }

  &__media {
    margin-bottom: 20px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include media($tablet) {
      width: 40%;
      float: left;
      margin-bottom: 0;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 72%;
    }
  }

  &__content {
    @include media($tablet) {
      width: 60%;
      padding: 30px 0 30px 20px;
      float: right;
    }
    &-date {
      display: block;
      margin-bottom: 15px;
      color: #353535;
    }

    &-title {
      margin-bottom: 10px;
      color: #353535;
    }

    &-body {
      margin: 0;
      color: #353535;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  width: 100%;
  &__prev {
    text-align: left;
    a {
      text-transform: uppercase;
      color: #438192;
      line-height: 1;
    }
  }

  &__pages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &-page {
      display: block;
      line-height: 1;
      color: #438192;
      width: 30px;
      height: 30px;
      margin: 0 5px;
      border-radius: 50%;
      line-height: 37px;
      text-align: center;
      &--active {
        background-color: #438192;
        color: white;
      }
    }
  }

  &__next {
    text-align: right;
    a {
      text-transform: uppercase;
      color: #438192;
      line-height: 1;
    }
  }
}
