.call-to-action {
  position: relative;
  min-height: 400px;
  padding: 20px 0;
  &--primary {
    background-color: $primary;
  }

  &--secondary {
    background-color: $theme-orange;
  }
  &_background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
      background-image: linear-gradient(-180deg, #a88075 2%, #6b4b43 27%, #553931 62%);
      content: '';
      opacity: 0.5;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
    }
  }

  &_wrapper {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    position: relative;
    max-width: 780px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    &_title {
      margin-bottom: 25px;
      color: #d99a2a;
    }

    &_subtitle {
      margin-bottom: 50px;
      color: #f7f4ea;
    }

    &_links {
      text-align: center;
    }
  }
}
