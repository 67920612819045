// *************************************
//
//  Globals
//   -> Universal margins/padding, border-box, clearfix
//
// *************************************


// -------------------------------------
//   Global Settings
// -------------------------------------

$border-box-sizing: true !default;
$gutter: 0 !default;
$grid-columns: 12 !default;
$max-width: 1200px !default;

* {
  margin: 0 0 0 0;
  padding: 0;
}

*,
*:after,
*::before {
    box-sizing: border-box;
}

@mixin clear {
  &:after{
    clear: both;
      content: "";
      display: table;
      zoom : 1;
  }
  &:before{
    clear: both;
      content: "";
      display: table;
      zoom : 1;
  }
}

html,
body{
  height: 100%;
}

body {
  overflow-x: hidden;
}

*:focus {
    outline: none;
}

textarea,
input[type="text"],
input[type="number"],
input[type="email"] {
  appearance: none;
  border-radius: 0;
}

input[type="button"],
input[type="submit"] {
  appearance: none;
}

// prevent slider buttons move when clicked in IE
button:active:before, button:focus:before {
    position:relative;
    top: 0;
    left: 0;
}
