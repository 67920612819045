.blog-details {
  padding-bottom: 4rem;
  min-height: 80vh;
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .wysiwyg {
    padding-top: 0;
    @media screen and (max-width: 991px) {
      padding: 30px 20px;
    }
  }
}
