// *************************************
//
//   Variables
//   Edit the information below to establish
//   base variables througout your document.
//
// *************************************

// -------------------------------------
//   Color Defaults: All colors can be
//   modified/added to in the themes section
// -------------------------------------

// ----- 1. WYSIWYG Colors ----- //

$red: #C40000;
$gray: #5F5F5F;
$white: #FFFFFF;
$black: #2B2B2B;
$yellow: #fef471;
$blue: #63ace2;
$red: rgba(206, 104, 67, 1);


// ----- 2. Default Site Context Colors ----- //

$primary : #4F748A !default;
$secondary : $gray!default; // black
$tertiary : $yellow!default; // yellow

$alert : #c4251b!default; // red
$success : #2f9646!default; // green
$warning : #f49224!default; // yellow

$theme-teal: #4E676E;
$theme-blue: #4D94A8;
$theme-green: #6F8463;
$theme-orange: #CE6843;
$theme-yellow: #D99A2A;
$theme-red: #934637;

$theme-dark-blue: #0A3955;
$text: #353535;

$top-section-yellow: #FFBB00;
$top-section-blue: #80C7DB;
// ----- 3. Other Colors (If Applicable) ----- //

$gray-light: #F8F8F8;
$gray-offset: #ebebeb;
$gray-medium: #c4c4c4;
$gray-divider: #EAEBEC;

@mixin text-shadow0 {
	text-shadow: 0px 0px 8px rgba(0,0,0, 0.7);
}
@mixin box-shadow0 {
	box-shadow: 0px 0px 8px rgba(0,0,0, 0.7);
}
@mixin box-shadow1 {
	box-shadow: 0px 0px 18px rgba(0,0,0, 0.7);
}
