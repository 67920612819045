$gutter: 0 !default;

.container-fluid {
  @include outer-container(100%);
}

.container {
  max-width: 1200px;
  margin: auto;
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}
.row {
  @include row();
}

$i: 1;
$j: 12;
@while $i <= $j {
  // Omega
  .no-gutter {
    @include omega;
  }
  .no-gutter-#{$i} {
    @include omega(#{$i});
  }
  .col-#{$i} {
    @include span-columns($i);
    width: calc((100% * #{$i}) / 12);
    margin: 0;
  }
  .push-#{$i} {
    margin-left: calc((100% * #{$i}) / 12) !important;
  }
  .pull-#{$i} {
    margin-left: calc((-100% * #{$i}) / 12) !important;
  }
  @include media($desktop) {
    .col-md-#{$i} {
      @include span-columns($i);
      width: calc((100% * #{$i}) / 12);
      margin: 0;
    }
    .push-md-#{$i} {
      margin-left: calc((100% * #{$i}) / 12) !important;
    }
    .pull-md-#{$i} {
      margin-left: calc((-100% * #{$i}) / 12) !important;
    }
    .no-gutter-md {
      @include omega;
    }
    .no-gutter-md-#{$i} {
      @include omega(#{$i});
    }
  }
  @include media($tablet) {
    .col-sm-#{$i} {
      @include span-columns($i);
      width: calc((100% * #{$i}) / 12);
      margin: 0;
    }
    .push-sm-#{$i} {
      margin-left: calc((100% * #{$i}) / 12) !important;
    }
    .pull-sm-#{$i} {
      margin-left: calc((-100% * #{$i}) / 12) !important;
    }
    .no-gutter-sm {
      @include omega;
    }
    .no-gutter-sm-#{$i} {
      @include omega(#{$i});
    }
  }
  @include media($mobile-only) {
    .col-xs-#{$i} {
      @include span-columns($i);
      width: calc((100% * #{$i}) / 12);
      margin: 0;
    }
    .push-xs-#{$i} {
      margin-left: calc((100% * #{$i}) / 12) !important;
    }
    .pull-xs-#{$i} {
      margin-left: calc((-100% * #{$i}) / 12) !important;
    }
    .no-gutter-xs {
      @include omega;
    }
    .no-gutter-xs-#{$i} {
      @include omega(#{$i});
    }
  }
  $i: $i + 1;
}
.clearfix {
  @include clearfix;
}
